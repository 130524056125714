import React from 'react'
import styles from './privacy-and-policy.module.css'
import Skeleton from '../components/Skeleton'
import config from '../config'

const PrivacyAndPolicy = () => {
    const {
        privacy: {
            bannerImage,
            customHero
        }
    } = config; 

    return (
        <Skeleton
          disableFooterImage={true}
          bannerImage={bannerImage}
          customHero={customHero}
        >
            <div className={styles.privacyAndPolicy}>
                <HTML />
            </div>
        </Skeleton>
      )
}

export default PrivacyAndPolicy

function HTML() {
    return (
        <>

<p>Effective as of 29 October 2021</p>

<p>This Privacy Policy describes the privacy practices of Factory 14 S.à r.l. (“Factory 14”, “we” or “us”) and our subsidiaries and affiliates.</p>
<p>​</p>
<p>Factory 14 is an aggregator of businesses that sell consumer packaged goods to customers via Amazon, amongst other platforms. This privacy policy explains how we handle the personal information of:</p>
<p>​</p>
<ol><li>business owners who look to sell their businesses to us; and</li>
<li>consumers who interact with us or our brands through Amazon or visit our website: www.factory14.com, or other sites we own and operate. </li></ol>
<p> </p>
<p>If you buy our brands’ products on Amazon, this Privacy Policy explains how we will process your information.  The processing of your information is also governed by Amazon’s privacy statement. Depending on the territory in which you are located, other privacy policies or statements may apply.</p>
<p>​</p>
<p>Some of our brands may also have direct-to-consumer websites that have their own specific privacy policies. Where applicable, our processing of the information we collect on the brands’ sites is governed by the brands’ policies.</p>
<p>​</p>
<h3>Personal information we collect</h3>
<p><strong>​</strong></p>
<p>Information you provide to us:</p>
<p>​</p>
<ol><li>Business and personal contact information, such as your first and last name, email and mailing addresses, phone number, professional title, and company name.</li>
<li>Registration Information, such as information that may be related to a service, an account, or an event you register for.</li>
<li>Feedback or correspondence, such as information you provide when you contact us with questions, feedback, or otherwise correspond with us online.</li>
<li>Usage information, such as information about how you use the website or services and interact with us, including information associated with any content you upload to the websites or otherwise submit to us, and information you provide when you use any interactive features of the website or services.</li>
<li>Marketing information, such as your preferences for receiving communications about our activities, events, and publications, and details about how you engage with our communications.</li>
<li>Information we obtain from other third parties. We may receive personal information about you from third-party sources. For example, we may receive your contact and mailing information from Amazon to fulfill your orders, or a business partner may share your contact information with us if you have expressed interest in learning specifically about our services, or the types of products or services we offer. We may obtain your personal information from other third parties, such as marketing partners, publicly-available sources, and data providers.</li>
<li>Communications response data, such as whether you open emails we send you, and the links and other actions you take in response to the emails; and</li>
<li>Other information that we may collect which is not specifically listed here, but which we will use in accordance with this Privacy Policy or as otherwise disclosed at the time of collection.</li></ol>
<p>​</p>
<p>On our website www.factory14.com we don’t make use of cookies. See our Cookie Policy below for more information. Some of our brands may also have direct-to-consumer websites that have their own specific privacy policies and cookie policy.</p>
<p>​</p>
<h3>How we use your personal information</h3>
<p><strong>​</strong></p>
<p>We use your personal information for the following purposes and as otherwise described in this Privacy Policy or at the time of collection:</p>
<p> </p>
<p>To fulfill and address any comments or concerns in connection with Amazon orders.</p>
<p>​</p>
<p>To operate our website and services:</p>
<p>​</p>
<ol><li>Provide, operate, maintain, secure and improve our website and services</li>
<li>Provide information about our website and services</li>
<li>Communicate with you about our website and services, including by sending you announcements, updates, security alerts, and support and administrative messages</li>
<li>Communicate with you about events in which you participate</li>
<li>Understand your needs and interests, and personalize your experience with our website and services and our communications</li>
<li>Respond to your requests, questions and feedback</li>
<li>For research and development. To analyse and improve the website and services and to develop new products and services, including by studying use of our website and services.</li>
<li>For marketing and promotions. To engage in marketing activities, such as sending marketing communications or facilitating the advertising of our products and services on social media platforms or other websites or online services. You will have the ability to opt-out of our use of your personal information for marketing and promotional purposes.</li>
<li>To comply with law. As we believe necessary or appropriate to comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities.</li>
<li>For compliance, fraud prevention, and safety. To: (a) protect our, your or others’ rights, privacy, safety or property (including by making and defending legal claims); (b) enforce the terms and conditions that govern our website and services; and (c) protect, investigate and deter against fraudulent, harmful, unauthorized, unethical or illegal activity.</li>
<li>With your consent. In some cases we may specifically ask for your consent to collect, use or share your personal information, such as when required by law.</li>
<li>To create anonymous data. To create anonymous data from your personal information and other individuals whose personal information we collect. We make personal information into anonymous data by removing information that makes the data personally identifiable to you. We may use this anonymous data and share it with third parties for our lawful business purposes, including to analyze and improve our website and services and promote our business.</li></ol>
<p>​</p>
<h3>How we share your personal information</h3>
<p><strong>​</strong></p>
<ol><li>We do not share your personal information with third parties without your consent, except in the following circumstances or as described in this Privacy Policy:</li>
<li><strong>Within our group.</strong> We may share your personal information between affiliates for the purposes of monitoring and optimising business performance and to improve the services which we provide to our customers.</li>
<li><strong>Service providers.</strong> We may share your personal information with third-party companies and individuals that provide services on our behalf or help us operate our website and services (such as customer support, hosting, analytics, email delivery, marketing, and database management services).</li>
<li><strong>Professional advisors.</strong> We may disclose your personal information to professional advisors, such as lawyers, bankers, auditors and insurers, where necessary in the course of the professional services that they render to us.</li>
<li><strong>For compliance, fraud prevention and safety.</strong> We may share your personal information for the compliance, fraud prevention and safety purposes described above.</li>
<li><strong>Business transfers. </strong> We may sell, transfer or otherwise share some or all of our business or assets, including your personal information, in connection with a business transaction (or potential business transaction) such as a corporate divestiture, merger, consolidation, acquisition, reorganization or sale of assets, or in the event of bankruptcy or dissolution. In such a case, we will make reasonable efforts to require the recipient to honour this Privacy Policy.</li></ol>
<p>​</p>
<h3>Your choices</h3>
<p><strong>​</strong></p>
<p>In this section, we describe the rights and choices available to all users. Users who are located in California or the European Economic Area, Switzerland or the United Kingdom (collectively, “Europe”) can find additional information about their rights below.</p>
<p>​</p>
<h5><strong>Access or update your information</strong>. If you have registered for an account with us, you may review and update certain personal information in your account profile by logging into the account.</h5>
<p>​</p>
<h5><strong>Opt-out of marketing communications</strong>. You may opt out of marketing-related emails by following the opt-out or unsubscribe instructions at the bottom of the email. You may continue to receive service-related and other non-marketing emails.</h5>
<p>​</p>
<h5><strong>Cookies & browser web storage.</strong> Most browsers let you remove or reject cookies. To do this, follow the instructions in your browser settings. Many browsers accept cookies by default until you change your settings. Please note that if you set your browser to disable cookies, the services may not work properly. Similarly, your browser settings may allow you to clear your browser web storage. For more details, see our Cookie Policy below.</h5>
<p>​</p>
<h5><strong>Targeted online advertising.</strong> Some of the business partners that collect information about users’ activities on or through our website and services may be members of organizations or programs that provide choices to individuals regarding the use of their browsing behavior or mobile application usage for purposes of targeted advertising.</h5>
<p>​</p>
<p>Users may opt out of receiving targeted advertising on websites through members of the Network Advertising Initiative or the Digital Advertising Alliance. European users may opt out of receiving targeted advertising on websites through members of the European Interactive Digital Advertising Alliance. Please note that we also may work with companies that offer their own opt-out mechanisms and may not participate in the opt-out mechanisms that we linked above.</p>
<p>​</p>
<p>In addition, your mobile device settings may provide functionality to limit our, or our partners’, ability to engage in ad tracking or targeted advertising using the Google Advertising ID or Apple ID for Advertising associated with your mobile device.</p>
<p>​</p>
<p>If you choose to opt-out of targeted advertisements, you will still see advertisements online but they may not be relevant to you. Even if you do choose to opt out, not all companies that serve online behavioral advertising are included in this list, so you may still receive some cookies and tailored advertisements from companies that are not listed.</p>
<p>​</p>
<h5><strong>Do Not Track. </strong>Some Internet browsers may be configured to send “Do Not Track” signals to the online services that you visit. We currently do not respond to “Do Not Track” or similar signals. To find out more about “Do Not Track,” please visit https://www.allaboutdnt.com.</h5>
<p>​</p>
<h3>Other sites, mobile applications and services</h3>
<p><strong>​</strong></p>
<p>Our website may contain links to other websites, mobile applications, and other online services operated by third parties. These links are not an endorsement of, or representation that we are affiliated with, any third party. In addition, our content may be included on web pages or in mobile applications or online services that are not associated with us. We do not control third party websites, mobile applications or online services, and we are not responsible for their actions. Other websites and services follow different rules regarding the collection, use and sharing of your personal information. We encourage you to read the privacy policies of the other websites and mobile applications and online services you use.</p>
<p> </p>
<h3>Security practices</h3>
<p>​</p>
<p>We use reasonable organizational, technical and administrative measures designed to protect against unauthorized access, misuse, loss, disclosure, alteration and destruction of personal information we maintain.</p>
<p>Unfortunately, data transmission over the Internet cannot be guaranteed as completely secure. Therefore, while we strive to protect your personal information, we cannot guarantee the security of personal information. In the event that we are required to notify you about a situation involving your data, we may do so by email or telephone to the extent permitted by law.</p>
<p><strong>​</strong></p>
<p><strong>Job applicants</strong></p>
<p>​</p>
<p>When you visit the “Careers” portion of our website, we collect the information that you provide to us in connection with your job application. Our Careers page is powered by Workable. You can find Workable’s Privacy Policy <u>here</u>. This includes business and personal contact information, professional credentials and skills, educational and work history, and other information of the type that may be included in a resume. This may also include diversity information that you voluntarily provide. We use this information to facilitate our recruitment activities and process employment applications, such as by evaluating a job candidate for an employment activity, and monitoring recruitment statistics. We may also use this information to provide improved administration of the website, and as otherwise necessary (a) to comply with relevant laws or to respond to subpoenas or warrants served on Factory14; (b) to protect and defend the rights or property of Factorry14 or others; (c) in connection with a legal investigation; and/or (d) to investigate or assist in preventing any violation or potential violation of the law, this Privacy Policy, or the Terms of Use of our website.</p>
<p>​</p>
<h3>The “Grow with us?” feature</h3>
<p>​</p>
<p>If you provide information to use via the “Grow with us?” feature on our website, we collect the information that you provide through that form. We may also use this information to provide improvements to our inbound marketing efforts, and as otherwise necessary (a) to comply with relevant laws or to respond to subpoenas or warrants served on Factory14; (b) to protect and defend the rights or property of Factory14or others; (c) in connection with a legal investigation; and/or (d) to investigate or assist in preventing any violation or potential violation of the law or this Privacy Policy of our website.</p>
<p>​ </p>
<h3>International data transfers</h3>
<p>​</p>
<p>We are headquartered in Luxembourg and have service providers in other countries, and your personal information may be transferred to Luxembourg or other locations outside of your state, province, or country where privacy laws may not be as protective as those in your state, province, or country.</p>
<p> </p>
<p>European users should read the important information provided below about transfer of personal information outside of the European Union.</p>
<p> </p>
<h3>Children</h3>
<p>​</p>
<p>Our website and services are not intended for children, and we do not collect personal information from them.</p>
<p> </p>
<p>We define “<strong>children</strong>” as follows: for Residents outside of Europe: anyone under 13 years old; and for residents of Europe: anyone under 16 years old, or the age needed to consent to the processing of personal information in your country of residence.</p>
<p> </p>
<p>If we learn we have collected or received personal information from a child without verification of parental consent, we will delete the information. If you believe we might have any information from or about a child, please contact us at privacy@factory14.co.</p>
<p> </p>
<h3>Changes to this Privacy Policy</h3>
<p><strong>​</strong></p>
<p>We reserve the right to modify this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you by updating the date of this Privacy Policy and posting it on our website. We may also provide notification of changes in another way that we believe is reasonably likely to reach you, such as via e-mail (if you have an account where we have your contact information) or another manner through our website or services.</p>
<p>​</p>
<p>Any modifications to this Privacy Policy will be effective upon our posting the new terms and/or upon implementation of the new changes on our website (or as otherwise indicated at the time of posting). In all cases, your continued use of the website or services after the posting of any modified Privacy Policy indicates your acceptance of the terms of the modified Privacy Policy.</p>
<p>​</p>
<h3>How to contact us</h3>
<p><strong>​</strong></p>
<p>Please direct any questions or comments about this Policy or privacy practices to privacy@factory14.co.</p>
<p> </p>
<h4>Additional information for consumers located in California</h4>
<p><strong>​</strong></p>
<p>This section applies only to California residents. For purposes of this section, “Personal Information” has the meaning given in the California Consumer Privacy Act of 2018 (“CCPA”) but does not include information exempted from the scope of the CCPA.</p>
<p>​</p>
<p>Please note that the www.factory14.com website is designed for businesses and not intended for personal or household use.</p>
<p>​</p>
<p>Accordingly, we treat all personal information about any visitors to www.factory14.com as pertaining to individuals acting as business representatives, rather than in their personal capacity.</p>
<p>​</p>
<p>We do not sell personal information. </p>
<p>​</p>
<p>Your California privacy rights. The CCPA grants individuals whose information is governed by the CCPA the following rights:</p>
<p>​</p>
<p><strong>Information</strong>. You can request information about how we have collected and used your Personal Information during the past 12 months. We have made this information available to California residents without having to request it by including this Privacy Policy.</p>
<p> </p>
<p><strong>Access.</strong> You can request a copy of the Personal Information that we have collected about you during the past 12 months.</p>
<p> </p>
<p><strong>Deletion.</strong> You can ask us to delete the Personal Information that we have collected from you.</p>
<p> </p>
<p><strong>Opt-out of sales</strong>. If we sell your Personal Information, you can opt-out. In addition, if you direct us not to sell your Personal Information, we will consider it a request pursuant to California’s “Shine the Light” law to stop sharing your personal information covered by that law with third parties for their direct marketing purposes.</p>
<p> </p>
<p>You are entitled to exercise the rights described above free from discrimination.</p>
<p> </p>
<p>Please note that the CCPA limits these rights by, for example, prohibiting us from providing certain sensitive information in response to an access request and limiting the circumstances in which we must comply with a deletion request. If we deny your request, we will communicate our decision to you.</p>
<p>​</p>
<p>How to exercise your rights</p>
<p>​</p>
<p>You may exercise your California privacy rights described above as follows:</p>
<p> </p>
<p>Right to information, access and deletion. You can request to exercise your information, access and deletion rights by: emailing privacy@factory14.com, by mailing us directly at 121, Avenue de la Faïencerie L - 1511 Luxembourg.</p>
<p> </p>
<p><strong>Right to opt-out of the “sale” of your Personal Information.</strong> We do not sell your Personal Information.</p>
<p> </p>
<p>We use cookies and other tracking tools to analyse website traffic and facilitate advertising. If you would like to opt out of our (and our third-party advertising partners’) use of cookies and other tracking technologies, please review the instructions provided in the Online Tracking Opt-out Guide below.</p>
<p>​</p>
<p><strong>Identity verification</strong>. We will need to confirm your identity and California residency to process your requests to exercise your information, access or deletion rights. We cannot process your request if you do not provide us with sufficient detail to allow us to understand and respond to it.</p>
<p> </p>
<p><strong>Authorized agents.</strong> California residents can empower an “authorized agent” to submit requests on their behalf.</p>
<p>We will require the authorized agent to have a written authorization confirming that authority.</p>
<p>  </p>
<p>Notice to European users</p>
<p> </p>
<p>The information provided in this “Notice to European Users” section applies only to individuals in Europe.</p>
<p> </p>
<p>Personal information. References to “<strong>personal information</strong>” in this Privacy Policy are equivalent to “<strong>personal data</strong>” governed by European data protection legislation.</p>
<p> </p>
<p><strong>Controller.</strong> Factory14 is the controller of your personal information covered by this Privacy Policy for purposes of European data protection legislation.</p>
<p> </p>
<p><strong>Legal bases for processing.</strong> We use your personal information only as permitted by law. Our legal bases for processing the personal information described in this Privacy Policy are described below.</p>
<p> </p>
<p>Details regarding each processing purpose listed below are provided in the section above titled “How we use your personal information”.</p>
<p>Processing Purpose: To fulfil and address any comments or concerns in connection with Amazon order.</p>
<p> </p>
<p>Legal basis: Processing is necessary to perform the contract governing our provision of our website and services or to take steps that you request prior to signing up for the services. If we have not entered into a contract with you, we process your personal information based on our legitimate interest in providing the services you access and request.</p>
<p> </p>
<p><strong>Processing Purpose</strong>: To operate our website and services</p>
<p> </p>
<p><strong>Legal basis</strong>: Processing is necessary to perform the contract governing our provision of our website and services or to take steps that you request prior to signing up for the services. If we have not entered into a contract with you, we process your personal information based on our legitimate interest in providing the services you access and request.</p>
<p>​</p>
<p><strong>Processing Purpose</strong>: For research and development; For marketing and promotions; For compliance, fraud prevention and safety; To create anonymous data</p>
<p> </p>
<p><strong>Legal basis</strong>: These activities constitute our legitimate interests. We do not use your personal information for these activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law).</p>
<p>​</p>
<p><strong>Processing Purpose</strong>: To comply with law</p>
<p>​</p>
<p><strong>Legal basis</strong>: Processing is necessary to comply with our legal obligations.</p>
<p>​</p>
<p><strong>Processing Purpose</strong>: With your consent</p>
<p>​</p>
<p><strong>Legal basis</strong>: Processing is based on your consent. Where we rely on your consent you have the right to withdraw it any time in the manner indicated when you consent or in the services.</p>
<p>​</p>
<p><strong>Use for new purposes</strong>. We may use your personal information for reasons not described in this Privacy Policy where permitted by law and the reason is compatible with the purpose for which we collected it. If we need to use your personal information for an unrelated purpose, we will notify you and explain the applicable legal basis.</p>
<p>​</p>
<p><strong>Sensitive personal information</strong>. We ask that you not provide us with any sensitive personal information (e.g., social security numbers, information related to racial or ethnic origin, political opinions, religion or other beliefs, health, biometrics or genetic characteristics, criminal background or trade union membership) on or through the website or services, or otherwise to us. If you provide us with any sensitive personal information to us when you use the website or services, you must consent to our processing and use of such sensitive personal information in accordance with this Privacy Policy.</p>
<p>​</p>
<p>If you do not consent to our processing and use of such sensitive personal information, you must not submit such sensitive personal information through our website or services.</p>
<p>​</p>
<p><strong>Retention.</strong> We retain personal information for as long as necessary to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, accounting, or reporting requirements, to establish or defend legal claims, or for fraud prevention purposes.</p>
<p>​</p>
<p>To determine the appropriate retention period for personal information, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
<p>​</p>
<p>When we no longer require the personal information we have collected about you, we will either delete or anonymize it or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible. If we anonymize your personal information (so that it can no longer be associated with you), we may use this information indefinitely without further notice to you.</p>
<p>​</p>
<h5><strong>Your rights.</strong> European data protection laws give you certain rights regarding your personal information. If you are located in Europe, you may ask us to take the following actions in relation to your personal information that we hold:</h5>
<p>​</p>
<ol><li><strong>Access.</strong> Provide you with information about our processing of your personal information and give you access to your personal information.</li>
<li><strong>Correct.</strong> Update or correct inaccuracies in your personal information.</li>
<li><strong>Delete.</strong> Delete your personal information.</li>
<li><strong>Transfer.</strong> Transfer a machine-readable copy of your personal information to you or a third party of your choice.</li>
<li><strong>Restrict</strong>. Restrict the processing of your personal information.</li>
<li><strong>Object</strong>. Object to our reliance on our legitimate interests as the basis of our processing of your personal information that impacts your rights.</li></ol>
<p>​</p>
<p>You may submit these requests by email to privacy@factory14.com or our postal address provided above. We may request specific information from you to help us confirm your identity and process your request. Applicable law may require or permit us to decline your request. If we decline your request, we will tell you why, subject to legal restrictions. If you would like to submit a complaint about our use of your personal information or our response to your requests regarding your personal information, you may contact us or submit a complaint to the data protection regulator in your jurisdiction. You can find your data protection regulator here. In the UK the data protection regulator is the ICO, whose contact details can be found here.</p>
<p>​</p>
<p><strong>Cross-border data transfer</strong>. If we transfer your personal information out of Europe to a country not deemed by the European Commission to provide an adequate level of personal information protection, the transfer will be performed:</p>
<p>​</p>
<p>Pursuant to the recipient’s compliance with standard contractual clauses, EU-US Privacy Shield (or Swiss-US Privacy Shield, as applicable), or Binding Corporate Rules.</p>
<p>​</p>
<p>Pursuant to the consent of the individual to whom the personal information pertains.</p>
<p>​</p>
<p>As otherwise permitted by applicable European requirements.</p>
<p>​</p>
<p>You may contact us if you want further information on the specific mechanism used by us when transferring your personal information out of Europe.</p>
<p>​</p>
<h3>Cookie Policy</h3>
<p>​</p>
<p>This Cookie Policy explains how Factory14 (“Factory14,” “we”, “us” or “our”) uses cookies and similar technologies in connection with  any other website that we own or control and which posts or links to this Cookie Policy (collectively, the “Sites”). The website <u>www.factory14.com</u> does not make use of cookies. </p>


<p>What are cookies?</p>
<p>​</p>
<p>Cookies are small data files that are placed on your computer or mobile device when you visit a website.</p>
<p>​</p>
<p>Cookies serve different purposes, like helping us understand how a site is being used, letting you navigate between pages efficiently, remembering your preferences and generally improving your browsing experience.</p>
<p>​</p>
<p>Our Sites may use both session cookies (which expire once you close your web browser) and persistent cookies (which stay on your computer or mobile device until you delete them).</p>
<p>​</p>
<p>We use two broad categories of cookies: (1) first party cookies, served directly by us to your computer or mobile device, which we use to recognize your computer or mobile device when it revisits our Sites; and (2) third party cookies, which are served by service providers or business partners on our Sites, and can be used by these parties to recognize your computer or mobile device when it visits other websites. Third party cookies can be used for a variety of purposes, including site analytics, advertising and social media features.</p>
<p>​</p>
<p>What types of cookies and similar tracking technologies does Factory14 use on the Sites?</p>
<p>​</p>
<p>On the Sites, we use cookies and other tracking technologies in the following categories described below.</p>
<p>​</p>
<p>Type: Remarketing</p>
<p>​</p>
<p>Description: These cookies are used by advertising companies to collect information about how you use our Sites and other websites over time. These companies use this information to show you ads they believe will be relevant to you within our services and elsewhere, and to measure how the ads perform.</p>
<p>​</p>
<p>Who serves the cookies: Google Analytics</p>
<p>​</p>
<p>Google Analytics uses its own cookies. You can find out more information about Google Analytics cookies here and about how Google protects your data here. You can prevent the use of Google Analytics relating to your use of our Sites by downloading and installing a browser plugin available here.</p>
<p>​</p>
<p>How to control them: See ‘your choices’ below.</p>
<p>​</p>
<p>Type: Conversion</p>
<p>​</p>
<p>Description: These cookies are used to enable us to track your actions across our sites in order to capture the number of conversions completed by users.</p>
<p>​</p>
<p>Who serves the cookies: Google Analytics</p>
<p>​</p>
<p>How to control them: See ‘your choices’ below.</p>
<p>​</p>
<p>Google Analytics uses its own cookies. You can find out more information about Google Analytics cookies here and about how Google protects your data here. You can prevent the use of Google Analytics relating to your use of our Sites by downloading and installing a browser plugin available here.</p>
<p>​</p>
<p>Type: Analytics</p>
<p>​</p>
<p>Description: These cookies help us understand how our services are performing and being used.  These cookies may work with web beacons included in emails we send to track which emails are opened and which links are clicked by recipients.</p>
<p>​</p>
<p>Who serves the cookies: Google Analytics</p>
<p>​</p>
<p>How to control them: See ‘your choices’ below.</p>
<p>​</p>
<p>Google Analytics uses its own cookies. You can find out more information about Google Analytics cookies here and about how Google protects your data here. You can prevent the use of Google Analytics relating to your use of our Sites by downloading and installing a browser plugin available here.</p>
<p>​</p>
<p>Type: Essential</p>
<p>​</p>
<p>Description: These cookies are necessary to allow the technical operation of our services (e.g., they enable you to move around on a website and to use its features).</p>
<p>​</p>
<p>Who serves the cookies: Google Tag Manager</p>
<p>​</p>
<p>How to control them: See ‘your choices’ below.</p>
<p>​</p>
<p>Type: Functionality/performance</p>
<p>​</p>
<h3>Other technologies</h3>
<p>​</p>
<p>In addition to cookies, our Sites may use other technologies, such as Flash technology to pixel tags to collect information automatically.</p>
<p>​</p>
<h3>Browser Web Storage</h3>
<p><strong>​</strong></p>
<p>We may use browser web storage (including via HTML5), also known as locally stored objects (“LSOs”), for similar purposes as cookies. Browser web storage enables the storage of a larger amount of data than cookies.</p>
<p>​</p>
<p>Your web browser may provide the functionality to clear your browser web storage.</p>
<p>​</p>
<h3>Flash Technology</h3>
<p><strong>​</strong></p>
<p>We may use Flash cookies (which are also known as Flash Local Shared Object (“Flash LSOs”)) on our Sites to collect and store information about your use of our Sites. Unlike other cookies, Flash cookies cannot be removed or rejected via your browser settings. If you do not want Flash LSOs stored on your computer or mobile device, you can adjust the settings of your Flash player to block Flash LSO storage using the tools contained in the Website Storage Settings Panel. You can also control Flash LSOs by going to the Global Storage Settings Panel and following the instructions. Please note that setting the Flash Player to restrict or limit acceptance of Flash LSOs may reduce or impede the functionality of some Flash applications, including, potentially, Flash applications used in connection with our Sites.</p>
<p>​</p>
<h3>Web Beacons</h3>
<p>​</p>
<p>We may also use web beacons (which are also known as pixel tags and clear GIFs) on our Sites and in our HTML formatted emails to track the actions of users on our Sites and interactions with our emails. Unlike cookies, which are stored on the hard drive of your computer or mobile device by a website, pixel tags are embedded invisibly on webpages or within HTML formatted emails. Pixel tags are used to demonstrate that a webpage was accessed or that certain content was viewed, typically to measure the success of our marketing campaigns or engagement with our emails and to compile statistics about usage of the Sites, so that we can manage our content more effectively.</p>
<p>​</p>
<h3>Your choices</h3>
<p>​</p>
<p>Most browsers let you remove or reject cookies. To do this, follow the instructions in your browser settings.</p>
<p>Many browsers accept cookies by default until you change your settings. Please note that if you set your browser to disable cookies, the Sites may not work properly.</p>
<p>​</p>
<p>For more information about cookies, including how to see what cookies have been set on your computer or mobile device and how to manage and delete them, visit www.allaboutcookies.org. If you do not accept our cookies, you may experience some inconvenience in your use of our Sites. For example, we may not be able to recognize your computer or mobile device and you may need to log in every time you visit our Sites.</p>
<p> </p>
<p>Users may opt out of receiving targeted advertising on websites through members of the Network Advertising Initiative by clicking here or the Digital Advertising Alliance by clicking here. European users may opt out of receiving targeted advertising on websites through members of the European Interactive Digital Advertising Alliance by clicking here, selecting the user’s country, and then clicking “Choices” (or similarly-titled link Please note that we also may work with companies that offer their own opt-out mechanisms and may not participate in the opt-out mechanisms that we linked above.</p>
<p> </p>
<p>If you choose to opt-out of targeted advertisements, you will still see advertisements online but they may not be relevant to you. Even if you do choose to opt out, not all companies that serve online behavioral advertising are included in this list, and so you may still receive some cookies and tailored advertisements from companies that are not listed.</p>
<p> </p>
<p>For more information about how we collect, use and share your information, see our Privacy Policy.</p>
<p> </p>
<p>Changes</p>
<p> </p>
<p>Information about the cookies we use may be updated from time to time, so please check back on a regular basis for any changes.</p>
<p>​</p>
<p>Questions</p>
<p> </p>
<p>If you have any questions about this Cookie Policy, please contact us by email at <u>privacy@factory14.com</u>. </p>



        </>
    )
}